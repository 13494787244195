<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2 d-flex">
      <button class="button-primary text-white d-flex mr-1 px-[5px]">
        <span
          class="k-arrow-left-2 h-100 font-bold text-10"
          style="line-height: 1.5;"
          @click="$router.back()"
        />
      </button>
      <span class="text-10 text--dark">Invoice</span>
    </div>
    <ValidationObserver
      ref="formRules"
      v-slot="{ invalid }"
    >
      <BRow>
        <BCol md="7">
          <!-- form -->
          <BRow>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Partner <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Posisi"
                  rules="required"
                >
                  <VSelect
                    v-model="idPartner"
                    label="name"
                    :reduce="option => option.id"
                    :options="listPartners"
                    placeholder="Ketik untuk mencari..."
                    disabled
                    @search="(search) => search.length > 0 ? onSearch(search) : ''"
                    @input="handleInput"
                  />
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Periode <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Periode"
                  rules="required"
                >
                  <MonthlyPicker
                    v-model="periode"
                    :month-labels="monthlabel"
                    :max="maxDatePicker"
                    date-format="MMM YYYY"
                    :disabled="true"
                    :clear-option="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Tipe Pembayaran <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Tipe Pembayaran"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="isEarlyPayment"
                    class="mt-50"
                    :options="paymentOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Keterangan
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Keterangan"
                  rules="max:140"
                >
                  <b-form-textarea
                    v-model="notes"
                  />
                  <div class="flex">
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="self-end">{{ notes.length }}/140</small>
                  </div>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
          </BRow>
        </BCol>
        <BCol
          md="5"
          class="d-flex justify-content-end align-items-end pb-1 gap-[10px]"
        >
          <BButton
            aria-label="download"
            :disabled="loadDownload || loading"
            variant="outline-primary"
            :class="{ 'k-document-download': !loadDownload }"
            class="!text-[20px] w-fit h-[38px] px-[32px] rounded-[8px] !grid place-items-center disabled:!border-[#C2C2C2] disabled:!text-[#C2C2C2] disabled:pointer-events-none"
            @click="onDownloadInvoice"
          >
            <template v-if="loadDownload">
              {{ percentDownload }}%
            </template>
          </BButton>
          <button
            v-b-modal.confirmationSubmit
            class="button-primary fw-bold px-[40px] py-[8px]"
            :disabled="invalid || checkForm()"
          >
            Simpan
          </button>
        </BCol>
      </BRow>
    </ValidationObserver>

    <BRow>
      <BCol
        md="12"
        class="d-flex flex-column py-[20px] items-end border-t border-b pb-1 mb-1"
      >
        <div
          class="d-flex justify-content-end cursor-pointer gap-10 items-center"
          @click="visible = !visible"
        >
          <span class="text-8 text-danger">{{ visible ? 'Tutup' : 'Buka' }}</span>
          <span
            v-if="visible"
            class="k-arrow-up-2 h-100 font-bold text-10 text-danger"
          />
          <span
            v-else
            class="k-arrow-down-1 h-100 font-bold text-10 text-danger"
          />
        </div>

        <BCollapse
          v-model="visible"
          class="mt-[5px]"
        >
          <div class="d-flex flex-column items-end">
            <span class="text-8 text--dark font-bold">
              Total Biaya Admin: {{ IDR(isTax ? totalAdminFee - (pphValue) : totalAdminFee) }}
              <span v-if="isTax">{{ `( ${IDR(totalAdminFee)} - ${IDR(pphValue)} )` }}</span>
            </span>
            <template v-if="totalAdminFee > 0">
              <span class="text-8 text-[#828282] font-bold">PPN {{ ppn }}%: {{ IDR(ppnValue) }}</span>
              <span class="text-8 text--dark font-bold">Total Setelah PPN: {{ IDR((isTax ? totalAdminFee - (pphValue) : totalAdminFee) + (+ppnValue)) }}</span>
            </template>
            <span class="text-8 text--dark font-bold">Total Gaji Talent: {{ IDR(totalTalentSalary) }}</span>
            <span class="text-8 text--dark font-bold">Total Biaya Lainnya: {{ IDR(totalAdditionalCost) }}</span>
            <span class="text-8 text--dark font-bold">Xendit (Layanan Admin): {{ IDR(4440) }} </span>
          </div>
        </BCollapse>

        <span
          class="text-8 font-bold"
          style="color: #34A770;"
        >Total Nominal: {{ IDR(totalNominal) }}</span>

        <BFormCheckbox
          v-model="isTax"
          class="text-8"
        >
          Kenakan Pph 23
        </BFormCheckbox>
      </BCol>
      <BCol
        md="6"
        class="d-flex justify-content-start align-items-end pb-1"
      >
        <button
          v-b-modal.addTalent
          class="button-primary fw-bold px-[20px] py-[8px]"
          :disabled="!idPartner || (listTalentFromFetch.length === listTalentexist.length) || !items"
        >
          Tambah talent
        </button>
      </BCol>
    </BRow>
    <div id="table">
      <BTable
        :items="items"
        :fields="fields"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        show-empty
        :small="true"
      >
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(talent_name)="data">
          <div class="d-flex flex-column">
            <span>{{ data.item.talent_name }}</span>
            <span class="text-6 text-[#828282]">{{ data.item.role_name }}</span>
          </div>
        </template>
        <template #cell(action)="data">
          <span
            class="k-close-circle h-100 font-bold text-10 text--dark"
            @click="removeTalent(data.index)"
          />
        </template>
        <template #cell(discount)="data">
          <b-form-input
            v-model.number="data.item.discount"
            placeholder="%"
          />
        </template>
        <template #cell(talent_salary)="data">
          <ValidationProvider
            #default="{ errors }"
            name="Gaji Talent"
            rules="required|integer"
          >
            <money
              v-model="data.item.talent_salary"
              v-bind="money"
              class="form-control"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </template>
        <template #cell(additional_cost)="data">
          <ValidationProvider
            #default="{ errors }"
            name="Biaya Lainnya"
            rules="integer"
          >
            <money
              v-model="data.item.additional_cost"
              v-bind="money"
              class="form-control"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </template>
        <template #cell(admin_fee)="data">
          <VSelect
            v-model="data.item.admin_fee"
            label="description"
            :reduce="option => option"
            :options="listAdminFee.filter((el) => el.skill_id === data.item.role_id)"
            placeholder="Biaya Admin"
            taggable
            :clearable="false"
            class="custom-style"
            @input="setAdminFee(data.index)"
            @option:selected="setIndexAdminFee"
          >
            <template #selected-option="{ admin_fee, description }">
              <span>{{ IDR(admin_fee || description) }}</span>
            </template>
          </VSelect>
        </template>
      </BTable>
    </div>
    <ConfirmationSubmit
      :loading-submit="loadingSubmit"
      @confirm="confirmSubmit"
    />
    <AddTalent
      :talent-exist="listTalentexist"
      :list-talent="listTalentFromFetch"
      :partner-name="partnerName"
      @update="updateTalentList"
    />
  </BCard>
</template>

<script>
import VSelect from 'vue-select'
import MonthlyPicker from 'vue-monthly-picker'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { komtimAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { alertError, alertSuccess } from '@toast'
import { required } from '@validations'
import { Money } from 'v-money'
import { LABELMONTH, YEAR_MONTH, LABEL_MONTH_YEAR } from '@/libs/filterDate'
import { ppn, pph } from '@/constants/tax'
import { tableTalent } from './config'
import ConfirmationSubmit from './modal/ConfirmationSubmit.vue'
import AddTalent from './modal/AddTalent.vue'
import '@core/scss/vue/libs/vue-select.scss'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VSelect,
    ConfirmationSubmit,
    Money,
    MonthlyPicker,
    AddTalent,
  },
  data() {
    return {
      IDR,
      ppn,
      pph,
      fields: tableTalent,
      visible: false,
      loading: false,
      loadDownload: false,
      percentDownload: 0,
      loadingSubmit: false,
      alertError,
      alertSuccess,
      required,
      YEAR_MONTH,
      LABEL_MONTH_YEAR,

      idPartner: null,
      periode: null,
      listPartners: [],
      listAdminFee: [],
      listTalentFromFetch: [],
      items: [],
      indexSelected: null,
      isTax: false,
      money: {
        thousands: '.',
        prefix: 'Rp ',
        precision: 0,
        masked: false,
      },
      monthlabel: LABELMONTH,
      adminServicefee: 4440,
      partnerName: '',
      filterSkillsExist: [],
      paymentOptions: [
        { text: 'Pembayaran Awal', value: true },
        { text: 'Pembayaran Akhir', value: false },
      ],
      isEarlyPayment: null,
      notes: '',
      hideCosts: false,
      additionalCost: 0,
    }
  },
  computed: {
    calculatedItems() {
      return this.items.map(el => {
        const finalAdminFee = el.admin_fee - (el.admin_fee * (el.discount / 100))
        return ({
          ...el,
          final_admin_fee: finalAdminFee,
          total: finalAdminFee + Math.ceil((this.ppn / 100) * finalAdminFee) + el.talent_salary + el.additional_cost,
        })
      })
    },
    totalAdminFee() {
      return this.calculatedItems.reduce((a, b) => a + b.final_admin_fee, 0)
    },
    totalTalentSalary() {
      return this.calculatedItems.reduce((a, b) => a + b.talent_salary, 0)
    },
    totalAdditionalCost() {
      return this.calculatedItems.reduce((a, b) => a + b.additional_cost, 0)
    },
    ppnValue() {
      return Math.ceil((this.ppn / 100) * this.totalAdminFee)
    },
    pphValue() {
      return Math.ceil((this.pph / 100) * this.totalAdminFee)
    },
    totalNominal() {
      return (this.isTax ? this.totalAdminFee - (this.pphValue) : this.totalAdminFee) + this.totalTalentSalary + 4440 + this.totalAdditionalCost + this.ppnValue
    },
    maxDatePicker() { return this.$moment().endOf('month') },
    listTalentexist() {
      return this.items.map(el => el.talent_id)
    },
    filterSkill() {
      return this.filterSkillsExist.join(',')
    },
  },
  mounted() {
    this.getDetailData()
    this.getListPartner('')
    this.getListAdminFee()
  },
  methods: {
    async confirmSubmit() {
      this.loadingSubmit = true

      const data = {
        partner_id: !Number.isInteger(this.idPartner) ? this.detailData.partner_id : this.idPartner,
        invoice_period: YEAR_MONTH(this.periode),
        invoice_details: this.calculatedItems,
        is_tax: this.isTax,
        total_admin_fee: this.totalAdminFee,
        total_talent_salary: this.totalTalentSalary,
        admin_service_fee: this.adminServicefee,
        total_nominal: this.totalNominal,
        is_early_payment: this.isEarlyPayment,
        notes: this.notes,
        hide_costs: this.hideCosts === null ? false : this.hideCosts,
        ppn_numerator: this.ppn,
      }

      const url = `v1/invoices/${this.$route.params.id}/update`
      await komtimAxiosIns
        .put(url, data)
        .then(() => {
          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)

          this.$router.push({ name: 'invoices-admin' })
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    async getDetailData() {
      const url = `v1/invoices/${this.$route.params.id}/detail`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailData = data

          this.loadForm()
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    async getListPartner(keyword) {
      const params = `&limit=20&status=active&keyword=${keyword}`
      const url = `v1/partners?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listPartners = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchPartner: _.debounce((search, it) => {
      it.getListPartner(search)
    }, 500),
    onSearch(search) {
      this.searchPartner(search, this)
    },
    async getListTalent() {
      const params = `partner_id=${this.detailData.partner_id}`
      const url = `v1/partner_assignments/invoice_resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listTalentFromFetch = data

          if (data) {
            const skills = data.map(el => el.role_id)
            this.filterSkillsExist = [...new Set(skills)]
            this.getListAdminFee()
          }
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListAdminFee() {
      const params = `limit=20&skill=${this.filterSkill}`
      const url = `v1/admin_fees?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listAdminFee = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    handleInput(val) {
      this.items = []
      if (val) this.getListTalent()
    },
    setAdminFee(index) {
      this.indexSelected = index
    },
    setIndexAdminFee(val) {
      if (val.description && !val.id) this.items[this.indexSelected].admin_fee = parseInt(val.description, 10)
      else if (val.description && val.id) {
        this.items[this.indexSelected].admin_fee = parseInt(val.admin_fee, 10)
        this.items[this.indexSelected].admin_fee_id = val.id
      }
    },
    loadForm() {
      this.items = this.detailData.data
      this.periode = this.detailData.invoice_period
      this.isTax = this.detailData.is_tax
      this.adminServicefee = this.detailData.admin_service
      this.idPartner = this.detailData.partner_name
      this.partnerName = this.detailData.partner_name
      this.isEarlyPayment = this.detailData.is_early_payment
      this.notes = this.detailData.notes
      this.hideCosts = this.detailData.hide_costs

      this.getListTalent()
    },
    removeTalent(index) {
      this.items.splice(index, 1)
    },
    updateTalentList(payload) {
      const data = payload.map(el => ({
        ...el,
        admin_fee: 0,
        admin_fee_id: null,
        talent_salary: 0,
        additional_cost: 0,
      }))
      this.items.push(...data)
    },
    checkForm() {
      const emptyAdminFee = this.items.filter(item => item.admin_fee === 0)
      const emptySalary = this.items.filter(item => item.talent_salary === 0)

      if (emptyAdminFee.length || emptySalary.length) return true
      return false
    },
    async onDownloadInvoice() {
      try {
        this.loadDownload = true
        const res = await komtimAxiosIns.get(`v1/invoices/download?invoice_code=${this.detailData.invoice_code}`, {
          responseType: 'blob',
          onDownloadProgress: progressEvent => {
            if (progressEvent.total) {
              this.percentDownload = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
          },
        })

        const blob = new Blob([res.data], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = url
        a.download = `invoice_active_${this.$route.params.id}_${this.detailData.invoice_period}.pdf`
        a.target = '_blank'
        a.click()

        a.remove()
        URL.revokeObjectURL(url)
      } catch (error) {
        this.$toast_error({ message: 'Gagal mengunduh invoice' })
      } finally {
        this.loadDownload = false
        this.percentDownload = 0
      }
    },
  },
}
</script>

<style lang="scss">
.custom-style .vs__dropdown-toggle {
  height: 37.77px;
}
.custom-style .vs__selected-options {
  width: 100px;
}

.status {
  padding: 1px 10px;
  text-transform: capitalize;
  border-radius: 5px;

  &-yellow {
    border: 1px solid #FBA63C;
    background: #FFF2E2;
    color: #FBA63C;
  }
}
</style>
